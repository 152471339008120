import React, { useEffect, useRef, useState, useTransition } from 'react'
import Footer from "../../Components/Footer";
import PagesHeader from '../../Components/PagesHeader';
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Triangle } from 'react-loader-spinner'
import { ApiService } from "../../Components/Services/apiservices";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import Testimonials from '../Home/Testimonials';

import Accordion from 'react-bootstrap/Accordion';
let apiServices = new ApiService()
function Menu() {
    const ref = useRef(null)
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({})
    const [headerImage, setHeaderImage] = useState("")
    const [menuCategory, setMenuCategory] = useState([])
    const [categorySlug, setCategorySlug] = useState("")
    const [menuData, setmenuData] = useState([])
    const [sppinerLoading, setSppinerLoading] = useState(false)
    const [pageContent, setPageContent] = useState('')
    const [pageChange, setPageChange] = useState(0)
    const [categoryBannerImage, setCategoryBannerImage] = useState("")
    const [menuImageUrl, setMenuImageUrl] = useState("")
    const [pdfCategoryName, setPdfCategoryName] = useState("")
    const [faqData, setfaqData] = useState([])
    const { slug } = useParams()
    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                "page_url": "menu"
            }
            apiServices.pagePostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    if (res.data.pageData != null) {
                        setPageData(res.data.pageData)
                        setPageContent(res.data.pageData.page_content)
                        setHeaderImage(res.data.page_header_url)
                        getfaqData(res.data.pageData?.page_id)
                    }
                }
            })
            setSppinerLoading(true)
            if (slug != null && slug != undefined) {
                const dataString2 = {
                    "category_slug": slug
                }
                apiServices.categorywisemenuPostRequest(dataString2).then(res => {
                    if (res.data.status == "success") {
                        setmenuData(res.data.menudata)
                        setPageChange(pageChange + 1)
                        setCategoryBannerImage(res.data.category.category_banner_image)
                        setMenuImageUrl(res.data.menu_url)
                        setPdfCategoryName(res.data.category.category_name)
                    }
                })
            }
            else {
                apiServices.menucategoryGetRequest().then(res => {
                    if (res.data.status == "success") {
                        setCategorySlug(res.data.category[0].category_slug)
                        setMenuCategory(res.data.category)
                        setMenuImageUrl(res.data.menu_url)
                    }
                })
                setSppinerLoading(false)
            }
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
        didMountRef.current = false;
    });

    const getfaqData = (page_id) => {
        apiServices.getFaqDataRequest(page_id).then(res => {
            if (res.data.status === "success") {
                setfaqData(res.data.faqdata)
            }
        }).catch(() => { })

    }
    return (
        <>
            <PagesHeader />
            <div id="cappa-main">


                <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center caption mt-90">
                                {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
                                {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                {pageChange == 0 ?
                    <section id="menu" className="restaurant-menu menu section-padding bg-blck pt-0" ref={ref}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="section-title" style={{ marginTop: "50px" }}><span>Menu</span></div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>

                                    <div className='mlist'>
                                        <ul>
                                            {menuCategory.map((value) => (
                                                <li style={{ color: "#aa8453", cursor: "pointer" }} className='butn-dark '>
                                                    {value?.category_slug == 'new-years-eve-menu'|| value?.category_slug=='christmas-menu' ? <a href={menuImageUrl + value.category_banner_image} target="new" className='gapmanage'><span>{value.category_name}</span></a> :<a href={`/menu/` + value.category_slug} className='gapmanage'><span>{value.category_name}</span></a>}


                                                </li>))}
                                        </ul>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section> : ""}
                {pageChange == 1 ?
                    <section id="menu" className="restaurant-menu menu section-padding bg-blck pt-0" ref={ref}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">

                                    <div className="section-title" style={{ marginTop: "50px" }}><span>{pdfCategoryName}</span></div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="restaurant-menu-content col-md-12 mt-5" >
                                        <div className='row text-center mb-5'>
                                            <div className='col-md-12'>
                                                <div className="butn-dark" ><a href={menuImageUrl + categoryBannerImage} target="new"><span>{pdfCategoryName}</span></a> </div>
                                            </div>
                                        </div>

                                        <div className="cont active" >
                                            {
                                                menuData.map((value) => (
                                                    <>
                                                        <div className='row text-center' >
                                                            <div className='col-md-12'>
                                                                <div className="section-title" style={{ fontSize: "30px" }}><span>{value.category_name}</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5" >
                                                            {value.menudata.map((subvalue) => (
                                                                <div className="col-md-6" >
                                                                    <div className="menu-info">
                                                                        <h5>{subvalue.menu_name}{subvalue.menu_starting_price != null ? <span className="price">{"£" + " " + subvalue.menu_starting_price}</span> : ""}</h5>

                                                                        {subvalue.menu_description != null ? <p>{subvalue.menu_description}</p> : ""}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : ""}
                {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}

                {faqData && faqData?.length > 0 && (
                    <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
                        <div className="container">

                            <div className="row">
                                <h2 className="text-center mb-5">Frequently Asked Questions</h2>
                                <Accordion defaultActiveKey="0" flush>
                                    {faqData?.map((item, index) => (
                                        <Accordion.Item eventKey={`${index}`} key={index}>
                                            <Accordion.Header>{item?.faq_title}</Accordion.Header>
                                            <Accordion.Body>
                                                <div dangerouslySetInnerHTML={{ __html: item?.faq_description }} style={{ color: 'black' }}></div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </section>
                )}
            </div>
            <Footer />
        </>
    )
}
export default Menu